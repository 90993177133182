.App {
  
  position: fixed;
}

.Layout {
  position: relative;
  height: 100vh;
  display: flex;
  display: -webkit-flex;
  align-items: stretch;
  width: 100vw;
}

div {
  border: 0px solid #bbb;
  font-family: monospace; 
  font-size: small;
}


