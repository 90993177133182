.ImagePreview {
    position: relative;
    background: #f9e3b1;
    margin: 3px 3px;
}

.large {
    width: 320px;
    height: 240px;
}

