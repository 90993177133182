.LabelWrapper {
    
    display: flex;
    display: -webkit-flex;
    border: 1px solid #bbb;
    font-family: monospace; 
    border-top-style: none;
    font-size: small;
   
}

.LabelWrapper div {
    overflow: hidden;
}

.LabelWrapper:first-child {
    border-top-style: solid;
}


.LabelKey {
    height: 14px;
    width: 70px;
    padding: 5px 5px;
    background-color: #c8dd96;
}

.LabelValue {
    height: 14px;
    flex-grow: 1;
    padding: 5px 5px;
    background-color: #ffe3b1;
    color: black;
    text-align: left;
}

