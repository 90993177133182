.Modal {
    position: fixed;
    z-index: 500;
    background-color: white;

    border: 1px solid #ccc;
    box-shadow: 1px 1px 1px black;
    padding: 16px;
    left: 50%;
    top: 30%;
    margin-left: -532px;
    box-sizing: border-box;
    transition: all 0.3s ease-out;
    overflow: hidden;
}

@media (min-width: 1100px) {
    .Modal {
    }
}