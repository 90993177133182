.Mask {
    position: absolute;
    z-index: 10;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-blend-mode: overlay;
    border: 1px solid #f9e3b1;
    pointer-events: none;

}
