.LeftBar {
    min-width: 326px;
    width: 326px;
    background: #e4d9c3;
    border-right: solid 1px #d5c9b4;
}

.LabelBlock {

}

.NextPrevButtons {
    position: relative;
}

.Button {
    position: absolute;
    top: -30px;
    width: 80px;
}

.Prev {
    composes: Button;
    left: 5px;

    
}

.Next  {
    composes: Button;
    right: 5px;
}

