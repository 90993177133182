.Caption {
    text-align: center;
    margin-top: 3px;
    padding: 3px;
    border: 1px solid #bbb;
    font-family: monospace;
    font-size: small;
    background: rgb(255, 215, 24);
    background: linear-gradient(
        97deg,
        rgba(255, 215, 24, 1) 0%,
        rgba(216, 198, 154, 1) 100%,
        rgba(108, 92, 22, 1) 100%
    );
}
