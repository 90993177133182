.SegmentPreview {
    min-height: 240px;
}

.SegmentPreview > div {
    display: flex;
    display: -webkit-flex;
}

.SegmentPreview .column {
    width: 50%;
}



.Nseq {
    padding: 5px 5px;
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    -webkit-flex-direction: column;
    align-items: center;
}


/* .HistItem {
    position: relative;
    background-color: cornflowerblue;
    text-align: center;
    margin: 1px;
}

.HistRow {
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.HistRowCaption {
    width: 8ch;
    text-align: center;
    background-color: blueviolet;
    
}

.HistRowElem:first-child {
}

.HistRowElem:last-child {
    text-align: right;
}

.HistProgress {
    position: absolute;
    left: 60px;
    top: 0;
    width: 75%;
    height: 100%;
    background-color: aquamarine;
} */

.HistogramContainer {
    padding-top: 5px;
    padding-bottom: 3px;
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    -webkit-flex-direction: column;
}

.HistRow {
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
    -webkit-flex-direction: row;
}

.HistCaption {
    width: 8ch;
    text-align: center;
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
    -webkit-flex-direction: row;
    justify-content: space-between;
    padding-left: 3px;
}

.From {
    text-align: left;
}
.Till {
    text-align: right;
    width: 3ch;
}
.HistZone {
    flex-grow:1;
    text-align: center;
    position: relative;
    background-color: lightyellow;
    margin: 0px 5px;
}

.HistCounter {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index:10;
}
.HistProgress {
    position: absolute;
    left: 0px;
    top: 0;
    height: 100%;
    background-color: #42ce9f;
    z-index: 5;
}


.Worst {
}

.Best {
    /*background-color: ivory;*/
}
