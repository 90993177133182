.Streets {
    height: 60%;
}

.Street {
    background-color: #d7e2d9;
    font-size: smaller;
    padding: 0px 2px;
    display: flex;
    display: -webkit-flex;
    min-height: 20px;

}
.Caption {
    background-color: white;
}
.Selection {
    background-color: lightblue;
}

.Street > div {
    overflow-x: hidden;
    padding: 3px 2px;
    cursor: pointer;
}

.City {
    flex-grow: 1;
    text-align: right;
}

.Road {
    flex-grow: 3;
}

.StreetContainer {
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    -webkit-flex-direction: column;
    overflow-y: scroll;
    height: 100%;
    margin: 2px;
}
