.Thumbnail {
    position: relative;
    overflow: hidden;
    margin: 3px;
}

.Thumbnail img {
    position: absolute;
}
.ThumbnailMask {
    pointer-events: none;
    position: absolute;
    background-size: cover;
    background-blend-mode: screen;
    z-index: 10;
    left: 0px;
    top: 0px;

}
